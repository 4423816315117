.button {
    cursor: pointer;
    color: var(--neutral-80);
    background-color: var(--neutral-8);
    border: 0;
    border-radius: var(--radius);
    border-top: 1px solid  var(--neutral-8);
    display: flex;
    flex-direction: row;
    animation: fadeIn 0.3s;
    transition: all 0.2s;
    font-size: var(--font-normal);
    outline: 0;
  }
  
  .button .iconContainer {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button .center {
    flex-grow: 2;
  }
  
  .button span {
    display: flex;
    align-items: center;
    column-gap: var(--space-2x);
  }
  
  .button.alignLeft {
    justify-content: space-between;
    align-items: center;
  }
  
  .button.alignCenter {
    justify-content: center;
    align-items: center;
  }
  
  .button span {
    padding-bottom: 0.122rem;
  }
  
  .button.ghost {
    background-color: transparent !important;
    border: 0 !important;
    color: var(--neutral-60);
    padding: 0 !important;
  
  }
  
  .button.ghost:hover {
    color: var(--neutral-95);
    /* border: 1px solid var(--neutral-4); */
  }
  
  .button.ghost:hover svg path {
    fill-opacity: 0.6;
  }
  
  .button:hover {
    background-color: var(--neutral-12);
    color: var(--neutral-95);
  }
  
  .button:active {
    background-color: var(--neutral-4);
    border-top: 1px solid transparent;
    color: var(--neutral-95);
  }
  
  .button.default {
    padding: var(--space-2x) var(--space-3x);
    gap: var(--space-3x);
    font-weight: 500;
    min-height: 38px;
  }
  
  .button.s {
    font-size: var(--font-s);
    line-height: var(--font-s);
    gap: var(--space-2x);
    padding: var(--space) var(--space-2x);
    height: 22px;
  }
  
  
  /* Animations */
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  