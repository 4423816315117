/* Colors */

:root {
  --main-bg: hsl(234, 25%, 10%);
  --main-bg-4: hsl(234, 25%, 12%);
  --main-bg-4-95: rgba(38, 40, 56, 0.95);
  --main-bg-4-98: rgba(38, 40, 56, 0.98);
  --main-bg-8: #2F3140;
  --main-bg-8-95: rgba(47, 49, 64, 0.95);
  --main-bg-12: #373948;

  --primary: #E6713A;
  --primary-4: rgba(230, 113, 58, 0.04);
  --primary-15: rgba(230, 113, 58, 0.15);
  --primary-30: rgba(230, 113, 58, 0.3);

  --neutral: rgba(255, 255, 255, 0.95);
  --neutral-4: rgba(255, 255, 255, 0.04);
  --neutral-8: rgba(255, 255, 255, 0.08);
  --neutral-12: rgba(255, 255, 255, 0.12);
  --neutral-16: rgba(255, 255, 255, 0.16);
  --neutral-25: rgba(255, 255, 255, 0.25);
  --neutral-35: rgba(255, 255, 255, 0.35);
  --neutral-50: rgba(255, 255, 255, 0.50);
  --neutral-60: rgba(255, 255, 255, 0.60);
  --neutral-80: rgba(255, 255, 255, 0.80);
  --neutral-95: rgba(255, 255, 255, 0.95);

  /* Semantic */
  --text-primary: rgba(255, 255, 255, 0.95);
  --text-secondary: rgba(255, 255, 255, 0.50);
  --text-error: rgb(195, 65, 58);
  --text-success: rgb(104, 213, 77);
}

/* Dimension tokens */
:root {
  --space-1-2: 2px;
  --space: 4px;
  --space-2x: 8px;
  --space-3x: 12px;
  --space-4x: 16px;
  --space-8x: 32px;

  --radius: 3px;
  --radius-2x: 6px;

  --sidebar-width: 320px;
  --main-content-width: 760px;
  --searchbar-height: calc(var(--titlebar-height) * 2 + 32px);
}


/* Typography sizes */

:root {
  --font-l: 15px;
  --font-normal: 14px;
  --font-s: 12px;
  --font-xs: 10px;

  --font-weight: 300;
}

/* Typography styles */

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/Inter-Thin.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("fonts/Inter-ThinItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/Inter-ExtraLight.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("fonts/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/Inter-Light.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("fonts/Inter-LightItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Inter-Regular.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("fonts/Inter-Italic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/Inter-Medium.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/Inter-MediumItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/Inter-SemiBold.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("fonts/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/Inter-Bold.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("fonts/Inter-BoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("fonts/Inter-ExtraBold.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("fonts/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("fonts/Inter-Black.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("fonts/Inter-BlackItalic.woff2?v=3.19") format("woff2");
}

h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.text-block > h1,
.text-block > h2,
.text-block > h3,
.text-block > h4,
.text-block > h5 {
  line-height: 150%;
}

h1,h2,h3,h4,h5 {
  line-height: 100%;
}

p, span {
  line-height: 150%;
  /* letter-spacing: 0.025rem; */
}

h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

h2 {
  font-size: 1.1rem;
}

h3 {
  font-size: 1.05rem;
  font-weight: 400;
}

h4 {
  font-size: 0.95rem;
  font-weight: 400;
}

h5 {
  font-size: 0.925rem;
  font-weight: 400;
}


/* Base app styles */

* {
  font-family: 'Inter', sans-serif;
}

html, body, #root {
  position: relative;
  margin: 0;
  padding: 0;
  color: var(--neutral);
  font-size: var(--font-normal);
  background-color: var(--main-bg);
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background:  linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 63.16%), #1C1D2E;
  color: white;
}
