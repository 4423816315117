.container {
    max-width: 560px;
    padding: var(--space-4x);
}

.container p {
    color: var(--neutral-80);
}

.youTubeVideo {
    margin-top: var(--space-4x);
    margin-bottom: var(--space-8x);
}

.youTubeVideo iframe {
    width: 560px;
    height: 315px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

@media only screen and (max-width: 600px)  {
    .container {
        width: 100%;
    }

    .youTubeVideo {
        display: flex;
        align-items: center;
        justify-self: center;
    }

    .youTubeVideo iframe {
        width: 100%;
        height: 315px;
    }    
}